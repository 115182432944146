.ui.menu .item img.logo {
  margin-right: 1.5em;
}

.main.container {
    margin-top: 5em;
}

ul.ui.list li:before {
  display: none;
  content: '';
}
